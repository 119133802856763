import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Grid } from 'react-flexbox-grid';

import PageTitle from 'components/page-title/page-title';
import Section from 'components/section/section';

const NotFoundPage = () => (
    <>
        <PageTitle title="404" />

        <Grid>
            <Section>404</Section>
        </Grid>
    </>
);

export const Head = ({ data }: any) => (
    <>
        <title>{data.directus.pages.title}</title>
        <meta name="description" content={data.directus.pages.description} />
        <meta name="robots" content={data.directus.robots} />
    </>
);

export const query = graphql`
    query {
        directus {
            pages(filter: { slug: { _eq: "404" }}) {
                title
                description
                robots
            }
        }
    }
`;

export default NotFoundPage;